@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #F5E6D3 !important;
  color: #4A3728 !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: #8B4513;
  color: #F5E6D3;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: #8B4513;
}

p.danger {
	color: #8B4513;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #E8D8C3;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link,
nav.navbar .navbar-nav .dropdown-toggle.navbar-link {
  font-weight: 400;
  color: #4A3728 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
  display: inline-flex;
  align-items: center;
}

nav.navbar .navbar-nav .nav-link.navbar-link:hover,
nav.navbar .navbar-nav .nav-link.navbar-link.active,
nav.navbar .navbar-nav .dropdown-toggle.navbar-link:hover,
nav.navbar .navbar-nav .dropdown-toggle.navbar-link.active {
  opacity: 1;
}

.navbar-nav .dropdown-menu {
  background-color: #E8D8C3;
  border: none;
  border-radius: 0;
}

.navbar-nav .dropdown-item {
  color: #4A3728 !important;
  font-weight: 400;
  letter-spacing: 0.8px;
  padding: 10px 25px;
  font-size: 18px;
  opacity: 0.75;
}

.navbar-nav .dropdown-item:hover,
.navbar-nav .dropdown-item:focus {
  background-color: transparent;
  opacity: 1;
}

.navbar .dropdown-toggle::after {
  display: none;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid #4A3728;
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #4A3728;
  border: 1px solid #4A3728;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span a {
  z-index: 1;
  color: #4A3728;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #4A3728;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover a {
  color: #F5E6D3;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (max-width: 991px) {
  nav.navbar .navbar-nav .nav-link.navbar-link,
  nav.navbar .navbar-nav .dropdown-toggle.navbar-link {
    padding: 10px 25px;
  }
  
  .navbar-nav .dropdown-menu {
    background-color: transparent;
    padding-left: 25px;
  }
}

.responsive-navbar {
  transition: background-color 0.3s ease;
}

@media (max-width: 991px) {
  .responsive-navbar {
    background-color: #E8D8C3;
  }

  .responsive-navbar.navbar-expand-lg .navbar-collapse {
    background-color: #E8D8C3; 
  }
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 150px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, #8B4513 -5.91%, #4A3728 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #B8B8B8;
  padding-top: 20px;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #4A3728;
  font-weight: 700;
  font-size: 20px;
  margin-top: 50px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.banner-content {
  background-color: rgba(245, 230, 211, 0.7);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(74, 55, 40, 0.1);
}

.banner-content .tagline,
.banner-content h1,
.banner-content h2,
.banner-content p {
  color: #4A3728; 
}

.banner-content button {
  background-color: #8B4513;
  border: none;
  color: #F5E6D3;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.banner-content button:hover {
  background-color: #4A3728; 
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}
.skill-bx {
  background: #8B4513;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ offers Css ************/
.offer {
  padding: 80px 0;
  position: relative;
  background-color: #4A3728;
}
.offer h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  color: #E8D8C3;
}
.offer h3 {
	font-size: 35px;
	font-weight: 700;
  text-align: center;
}
.offer p {
  color: #B8B8B8;
  font-size: 15px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: left;
  width: 70%;
}
.offer .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.offer .nav.nav-pills .nav-item {
  width: 50%;
}
.offer .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #F5E6D3;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.offer .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #8B4513 -5.91%, #4A3728 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.offer .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.offer .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#offers-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#offers-tabs-tab-first.active {
  border-radius: 55px 0px 0px 55px;
}
.nav-link#offers-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
}
.nav-link#offers-tabs-tab-second.active {
  border-radius: 0 55px 55px 0;
}
.off-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  aspect-ratio: 16 / 9;
  width: 100%;
}
.off-imbox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.off-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #8B4513 -5.91%, #4A3728 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.off-imgbx:hover::before {
  height: 100%;
}
.off-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.off-imgbx:hover .off-txtx {
  top: 50%;
  opacity: 1;
}
.off-txtx h4 {
  color:#E8D8C3;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.off-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ contact Css ************/
.contact {
  background: linear-gradient(90.21deg, #8B4513 -5.91%, #4A3728 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
  color: #E8D8C3;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #F5E6D3;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #4A3728;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #F5E6D3;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #4A3728;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #4A3728;
  background-color: #F5E6D3;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #F5E6D3;
}
.contact form button::before {
  content: "";
  background: #4A3728;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  background-color: #1c1c1c;
  color: white;
  padding: 30px 0;
  text-align: center;
}

.footer .social-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.footer .social-icon a {
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 10px;
  color: #1c1c1c;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer .social-icon a:hover {
  background-color: #ddd;
}

.footer .footer-menu {
  margin-bottom: 20px;
}

.footer .footer-menu a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer .footer-menu a:hover {
  color: #ddd;
}

.footer p {
  margin: 0;
  font-size: 12px;
  color: #888;
}

.scrollable-text {
  max-height: 150px; 
  overflow-y: auto; 
}

.wrapper-left {
  width: 90%;
  max-width: 1530px;
  margin-inline: auto;
  position: relative;
  height: 100px;
  margin-top: 5rem;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0,0,0,0),
    rgba(0,0,0,1) 20%,
    rgba(0,0,0,1) 80%,
    rgba(0,0,0,0)
  );
}

.wrapper-right {
  width: 90%;
  max-width: 1530px;
  margin-inline: auto;
  position: relative;
  height: 100px;
  margin-top: 5rem;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0,0,0,0),
    rgba(0,0,0,1) 20%,
    rgba(0,0,0,1) 80%,
    rgba(0,0,0,0)
  );
}

@keyframes scrollRight {
  to {
    right: -200px;
  }
}

@keyframes scrollLeft {
  to {
    left: -200px;
  }
}

.item-partners-left {
  width: 200px;
  height: 100px;
  border-radius: 6px;
  position: absolute;
  left: max(calc(200px * 11), 100%);
  animation-name: scrollLeft;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.item-partners-right {
  width: 200px;
  height: 100px;
  border-radius: 6px;
  position: absolute;
  right: max(calc(200px * 11), 100%);
  animation-name: scrollRight;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.item1, .item2, .item3, .item4, .item5, .item6, .item7, .item8, .item9, .item10, .item11, .item12, .item13, .item14, .item15, .item16, .item17, .item18, .item19, .item20, .item21, .item22 {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.item1 {
  animation-delay: calc(60s / 11 * (11 - 1) * -1);
  background-image: url(./assets/img/par/logo1.png);
}
.item2 {
  animation-delay: calc(60s / 11 * (11 - 2) * -1);
  background-image: url(./assets/img/par/logo2.png);
}
.item3 {
  animation-delay: calc(60s / 11 * (11 - 3) * -1);
  background-image: url(./assets/img/par/logo3.png);
}
.item4 {
  animation-delay: calc(60s / 11 * (11 - 4) * -1);
  background-image: url(./assets/img/par/logo4.png);
}
.item5 {
  animation-delay: calc(60s / 11 * (11 - 5) * -1);
  background-image: url(./assets/img/par/logo5.png);
}
.item6 {
  animation-delay: calc(60s / 11 * (11 - 6) * -1);
  background-image: url(./assets/img/par/logo6.png);
}
.item7 {
  animation-delay: calc(60s / 11 * (11 - 7) * -1);
  background-image: url(./assets/img/par/logo7.png);
}
.item8 {
  animation-delay: calc(60s / 11 * (11 - 8) * -1);
  background-image: url(./assets/img/par/logo8.png);
}
.item9 {
  animation-delay: calc(60s / 11 * (11 - 9) * -1);
  background-image: url(./assets/img/par/logo9.png);
}
.item10 {
  animation-delay: calc(60s / 11 * (11 - 10) * -1);
  background-image: url(./assets/img/par/logo10.png);
}
.item11 {
  animation-delay: calc(60s / 11 * (11 - 11) * -1);
  background-image: url(./assets/img/par/logo11.png);
}
.item12 {
  animation-delay: calc(60s / 11 * (11 - 1) * -1);
  background-image: url(./assets/img/par/logo12.png);
}
.item13 {
  animation-delay: calc(60s / 11 * (11 - 2) * -1);
  background-image: url(./assets/img/par/logo13.png);
}

.photo-gallery {
  padding: 200px 0 200px;
  min-height: 100vh;
}

.aspect-ratio-1x1 {
    aspect-ratio: 1 / 1;
}

.aspect-ratio-16x9 {
    aspect-ratio: 16 / 9;
}

.object-fit-cover {
    object-fit: cover;
}

.object-fit-contain {
    object-fit: contain;
}

.transition-transform {
    transition: transform 0.3s ease;
}

.hover-scale-110:hover {
    transform: scale(1.1);
}

.cursor-pointer {
    cursor: pointer;
}

.space-x-2 > * + * {
    margin-left: 0.5rem;
}

.docs-container {
  padding-top: 100px;
  padding-bottom: 100px;
}

.custom-tabs {
  border-bottom: 1px solid #8B4513;
}

.custom-tabs .nav-link {
  color: #F5E6D3;
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  margin-right: 5px;
  border-radius: 5px 5px 0 0;
  transition: background-color 0.3s ease;
}

.custom-tabs .nav-link:hover {
  background-color: rgba(139, 69, 19, 0.5);
}

.custom-tabs .nav-link.active {
  color: #F5E6D3;
  background-color: #8B4513;
  border: none;
}

.custom-tab {
  background-color: transparent;
  padding: 20px 0;
}

.card {
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.btn-gradient {
  background: linear-gradient(90.21deg, #8B4513 -5.91%, #4A3728 111.58%);
  border: none;
  color: #F5E6D3;
  transition: 0.3s;
}

.btn-gradient:hover {
  opacity: 0.9;
  color: #F5E6D3;
}

.btn-full-width {
  width: 100%;
  margin-top: 10px;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #F5E6D3 !important;
  color: #4A3728 !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: #8B4513;
  color: #F5E6D3;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: #8B4513;
}

p.danger {
	color: #8B4513;
}
/************ Navbar Css ************/
nav.navbar{
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #E8D8C3;
}
nav.navbar a.navbar-brand {
    width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link{
  font-weight: 400;
  color: #4A3728 !important;
  letter-spacing: 0.8px;
  font-size: 18px;
  opacity: 0.75;
  display: inline-flex;
  align-items: center;
}

.nav-dropdown-title {
  font-weight: 400;
  color: #4A3728 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
  display: inline-flex;
  align-items: center;
}

.nav-dropdown-title:hover,
.nav-dropdown-title.active {
  opacity: 1;
}

.nav-dropdown-item {
  font-weight: 400;
  color: #4A3728 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

.nav-dropdown-item:hover,
.nav-dropdown-item.active {
  opacity: 1;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #4A3728;
  border: 1px solid #4A3728;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span a {
  z-index: 1;
  color: #4A3728;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #4A3728;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover a {
  color: #F5E6D3;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #4A3728;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #4A3728;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #4A3728;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #4A3728;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #4A3728;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 150px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, #8B4513 -5.91%, #4A3728 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
  color: #E8D8C3 !important;
}
.banner h1 {
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #2e2e2e;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #4A3728;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid #4A3728;
  padding: 10px 20px;
  transition: 0.3s ease-in-out;
}
.banner button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.banner a {
  color: #4A3728;
  background-color: transparent;
  text-decoration: none;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}
.skill-bx {
  background: #8B4513;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ contact Css ************/
.contact {
  background: linear-gradient(90.21deg, #8B4513 -5.91%, #4A3728 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #F5E6D3;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #4A3728;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #F5E6D3;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #4A3728;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #4A3728;
  background-color: #F5E6D3;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #F5E6D3;
}
.contact form button::before {
  content: "";
  background: #4A3728;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  background-color: #8B4513;
  color: white;
  padding: 3rem 0;
  position: relative;
  overflow: hidden;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer .grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .footer .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.footer h3, .footer h4 {
  color: #F5E6D3;
}

.footer a {
  color: white;
  transition: color 0.3s;
}

.footer a:hover {
  color: #F5E6D3;
}

.footer .border-t {
  border-top: 1px solid #A0522D;
}

.footer .absolute {
  position: absolute;
}

.footer .animate-bounce-slow {
  animation: bounce 2s infinite;
}

.footer .animate-spin-slow {
  animation: spin 4s linear infinite;
}

.footer .animate-pulse {
  animation: pulse 2s infinite;
}

.footer .animate-float {
  animation: float 3s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.scrollable-text {
  max-height: 150px; 
  overflow-y: auto; 
}

.wrapper-left {
  width: 90%;
  max-width: 1530px;
  margin-inline: auto;
  position: relative;
  height: 100px;
  margin-top: 5rem;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0,0,0,0),
    rgba(0,0,0,1) 20%,
    rgba(0,0,0,1) 80%,
    rgba(0,0,0,0)
  );
}

.wrapper-right {
  width: 90%;
  max-width: 1530px;
  margin-inline: auto;
  position: relative;
  height: 100px;
  margin-top: 5rem;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0,0,0,0),
    rgba(0,0,0,1) 20%,
    rgba(0,0,0,1) 80%,
    rgba(0,0,0,0)
  );
}

@keyframes scrollRight {
  to {
    right: -200px;
  }
}

@keyframes scrollLeft {
  to {
    left: -200px;
  }
}

.item-partners-left {
  width: 200px;
  height: 100px;
  border-radius: 6px;
  position: absolute;
  left: max(calc(200px * 11), 100%);
  animation-name: scrollLeft;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.item-partners-right {
  width: 200px;
  height: 100px;
  border-radius: 6px;
  position: absolute;
  right: max(calc(200px * 11), 100%);
  animation-name: scrollRight;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.item1, .item2, .item3, .item4, .item5, .item6, .item7, .item8, .item9, .item10, .item11, .item12, .item13, .item14, .item15, .item16, .item17, .item18, .item19, .item20, .item21, .item22 {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.item1 {
  animation-delay: calc(60s / 11 * (11 - 1) * -1);
  background-image: url(./assets/img/par/logo1.png);
}
.item2 {
  animation-delay: calc(60s / 11 * (11 - 2) * -1);
  background-image: url(./assets/img/par/logo2.png);
}
.item3 {
  animation-delay: calc(60s / 11 * (11 - 3) * -1);
  background-image: url(./assets/img/par/logo3.png);
}
.item4 {
  animation-delay: calc(60s / 11 * (11 - 4) * -1);
  background-image: url(./assets/img/par/logo4.png);
}
.item5 {
  animation-delay: calc(60s / 11 * (11 - 5) * -1);
  background-image: url(./assets/img/par/logo5.png);
}
.item6 {
  animation-delay: calc(60s / 11 * (11 - 6) * -1);
  background-image: url(./assets/img/par/logo6.png);
}
.item7 {
  animation-delay: calc(60s / 11 * (11 - 7) * -1);
  background-image: url(./assets/img/par/logo7.png);
}
.item8 {
  animation-delay: calc(60s / 11 * (11 - 8) * -1);
  background-image: url(./assets/img/par/logo8.png);
}
.item9 {
  animation-delay: calc(60s / 11 * (11 - 9) * -1);
  background-image: url(./assets/img/par/logo9.png);
}
.item10 {
  animation-delay: calc(60s / 11 * (11 - 10) * -1);
  background-image: url(./assets/img/par/logo10.png);
}
.item11 {
  animation-delay: calc(60s / 11 * (11 - 11) * -1);
  background-image: url(./assets/img/par/logo11.png);
}
.item12 {
  animation-delay: calc(60s / 11 * (11 - 1) * -1);
  background-image: url(./assets/img/par/logo12.png);
}
.item13 {
  animation-delay: calc(60s / 11 * (11 - 2) * -1);
  background-image: url(./assets/img/par/logo13.png);
}
.item14 {
  animation-delay: calc(60s / 11 * (11 - 3) * -1);
  background-image: url(./assets/img/par/logo14.png);
}

.item15 {
  animation-delay: calc(60s / 11 * (11 - 4) * -1);
  background-image: url(./assets/img/par/logo15.png);
}

.item16 {
  animation-delay: calc(60s / 11 * (11 - 5) * -1);
  background-image: url(./assets/img/par/logo16.png);
}

.item17 {
  animation-delay: calc(60s / 11 * (11 - 6) * -1);
  background-image: url(./assets/img/par/logo17.png);
}

.item18 {
  animation-delay: calc(60s / 11 * (11 - 7) * -1);
  background-image: url(./assets/img/par/logo18.png);
}

.item19 {
  animation-delay: calc(60s / 11 * (11 - 8) * -1);
  background-image: url(./assets/img/par/logo19.png);
}

.item20 {
  animation-delay: calc(60s / 11 * (11 - 9) * -1);
  background-image: url(./assets/img/par/logo20.png);
}

.item21 {
  animation-delay: calc(60s / 11 * (22 - 10) * -1);
  background-image: url(./assets/img/par/logo21.png);
}

.item22 {
  animation-delay: calc(30s / 11 * (11 - 11) * -1);
  background-image: url(./assets/img/par/logo22.png);
}


/* Documents PDF */

.docs-page {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 0;
}

.doc-page .row {
  justify-content: center;
}

.docs-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.docs-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doc-gallery-item {
  background-color: #f8f9fa;
  color: black;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.doc-gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.doc-gallery-file-size {
  font-size: 12px;
  color: #6c757d;
  margin-bottom: 10px;
}

.doc-gallery-img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .doc-gallery-item {
    padding: 15px;
  }
}

/* Video */

.videos-gallery {
  padding-top: 200px; 
  padding-bottom: 115px;
}

.videos-gallery .container {
  max-width: 1200px;
  margin: 0 auto;
}

.videos-gallery .video-thumbnail {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  background-color: #8B4513;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.videos-gallery .video-thumbnail .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.videos-gallery .video-thumbnail:hover .video-overlay {
  opacity: 1;
}

.videos-gallery .video-thumbnail .play-icon {
  color: white;
  font-size: 3rem;
}

.videos-gallery .video-thumbnail .video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videos-gallery .video-thumbnail h3 {
  margin-top: 0.5rem;
  color: #F5E6D3;
}

.btn-gradient {
  background: linear-gradient(90.21deg, #8B4513 -5.91%, #4A3728 111.58%);
  border: none;
  color: #F5E6D3;
  transition: 0.3s;
}

.btn-gradient:hover {
  opacity: 0.9;
  color: #F5E6D3;
}

